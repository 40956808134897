import { format, isDate } from "date-fns";

// format date
export const formatDate = (date) => {
  if (isDate(new Date(date))) return format(new Date(date), "dd MMMM yyyy ");
  return false;
};
 
export const formatDateTime=(date)=>{
  if (isDate(new Date(date))) return format(new Date(date), "h:mm a");
  return false;
}

export const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date);
};