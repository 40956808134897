import React, { useEffect, useMemo, useState } from 'react';
import CommonTableCell from '../../sharedComponents/tables/CommonTableCell';
import StatusChip from '../../sharedComponents/tables/Chips';
import CommonTable from '../../sharedComponents/tables/CommonTable';
import { getColor } from '../../utils/getColor';
import { useFetchToken } from '../../awsServices/token';
import useToken from '../../hooks/useToken';
import { useGetAllLoans } from '../../awsServices/loans';
import { useQueryClient } from '@tanstack/react-query';
import { formatDate, formatDateTime } from '../../utils/formatDate';

export default function LoanTable() {
  const [rowSelection, setRowSelection] = useState({});
  const fetchTokenMutation = useFetchToken();
  const queryClient = useQueryClient();
  const { setToken, token } = useToken();
  const getAllLoansQuery = useGetAllLoans(token);
  const loans = getAllLoansQuery?.data?.data?.data;

  useEffect(() => {
    fetchTokenMutation.mutate(
      {},
      {
        onSuccess: ({ data }) => {
          console.log('Token fetched successfully:', data);
          setToken(data?.access_token); // Ensure the token is stored correctly
        },
        onError: (error) => {
          console.error('Error fetching token:', error);
        },
      }
    );
  }, [!token]);

  useEffect(() => {
    queryClient.refetchQueries({ queryKey: ['all loans'] });
  }, [token, queryClient]);

  const columns = useMemo(
    () => [
      {
        accessorKey: '#',
        header: '#',
        size: 50,
        enableSorting: true,
        Cell: ({ row }) => (
          <div>
            <CommonTableCell tableContent={(row?.index + 1).toString()} />
          </div>
        ),
      },
      {
        accessorFn: (row) => row?.user?.firstName,
        header: 'Full Name',
        size: 150,
        enableSorting: true,
        enableGlobalFilter: true,
        Cell: ({ row }) => {
          const fullname = `${row?.original?.user?.firstName} ${row?.original?.user?.lastName}`;
          return (
            <div>
              <CommonTableCell tableContent={fullname?.toString() ?? '-'} />
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row?.loanAccount?.accountNumber,
        header: 'Loan Account Number',
        size: 150,
        enableSorting: true,
        enableGlobalFilter: true,
        Cell: (row) => {
          const accountNumber = `${row?.row?.original?.loanAccount?.accountNumber}`;
          return (
            <div>
              <CommonTableCell tableContent={accountNumber?.toString() ?? '-'} />
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row?.requestedAmount,
        header: 'Principal Amount',
        size: 80,
        enableSorting: true,
        enableGlobalFilter: true,
        Cell: ({ row }) => {
          const amountRequested = row?.original?.requestedAmount ?? '-';
          return (
            <div>
              <CommonTableCell tableContent={amountRequested} />
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row?.disbursedAmount,
        header: 'Amount Disbursed',
        size: 80,
        enableSorting: true,
        enableGlobalFilter: true,
        Cell: ({ row }) => {
          const amountDisbursed = row?.original?.disbursedAmount ?? '-';
          return (
            <div>
              <CommonTableCell tableContent={amountDisbursed} />
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row?.repaymentAmount,
        header: 'Amount Repaid',
        size: 80,
        enableSorting: true,
        Cell: ({ row }) => {
          const amountRepaid = row?.original?.repaymentAmount - row?.original?.loanAccount?.balance;
          return (
            <div>
              <CommonTableCell tableContent={amountRepaid} />
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row?.interestRate,
        header: 'Interest Rate',
        size: 80,
        enableSorting: false,
        enableGlobalFilter: true,
        Cell: (row) => (
          <div>
            <CommonTableCell tableContent={row?.row?.original?.interestRate?.toString() ?? '-'} />
          </div>
        ),
      },
      {
        accessorFn: (row) => row?.repaymentDate,
        header: 'Repayment Date',
        size: 80,
        enableSorting: false,
        Cell: ({ row }) => {
          const repaymentDate = row?.original?.repaymentDate ?? '';
          return (
            <div>
              <CommonTableCell tableContent={repaymentDate.length > 0 ? formatDate(repaymentDate) : '-'} tableSubContent={repaymentDate.length > 0 ? formatDateTime(repaymentDate) : ''} />
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row?.createdAt,
        header: 'Date Requested',
        size: 150,
        enableSorting: false,
        Cell: ({ row }) => {
          const requestedDate = row?.original?.createdAt ?? '';
          return (
            <div>
              <CommonTableCell tableContent={requestedDate.length > 0 ? formatDate(requestedDate) : '-'} tableSubContent={requestedDate.length > 0 ? formatDateTime(requestedDate) : ''} />
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row?.loanAccount?.accountStatus,
        header: 'Account Status',
        size: 150,
        enableSorting: false,
        enableGlobalFilter: true,
        Cell: (row) => {
          const status = row?.renderedCellValue;
          return (
            <div>
              <StatusChip value={status} bgColor={getColor(status)} color={getColor(status)?.textColor} />
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row?.loanStatus,
        header: 'Loan Status',
        size: 150,
        enableSorting: false,
        enableGlobalFilter: true,
        Cell: (row) => {
          const status = row?.renderedCellValue;
          return (
            <div>
              <StatusChip value={status} bgColor={getColor(status)} color={getColor(status)?.textColor} />
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div>
      <CommonTable
        columns={columns}
        data={loans?.results ?? []}
        hasSelectAll={true}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
      />
    </div>
  );
}
