export const COLORS={
    green:'#009966',
    darkgreen:'#049060',
    lightGreen:'#c7feeb',
    lightGray:'#f2f2f2',
    backgroundColor:'#f2fffb',
    black:'#212427',
    red:'#C53A16',
    lightRed:'#ffefed',
    yellow:'#c79200',
    lightYellow:'#fff2cd'

}

export const STATUS = {
    Disbursed: 'DISBURSED',
    Pending: 'PENDING',
    Requested: 'REQUESTED',
    Active: 'ACTIVE',
    Defaulted: 'DEFAULTED',
    Inactive : 'INACTIVE',
    Closed :'CLOSED',
    ApplicationFailed : 'APPLICATION_FAILED',
    Failed: 'FAILED',
    Rejected: 'REJECTED',
    PendingDisbursement:'PENDING_DISBURSEMENT',
    PartiallyPaid:'PARTIALLY_PAID',
    Cancelled:'CANCELLED',
    Completed:'COMPLETED'
  };

 export const STATUS_COLORS={
    // yellow
    [STATUS.Pending]:{backgroundColor:COLORS.lightYellow,textColor:COLORS.yellow},
    [STATUS.Requested]:{backgroundColor:COLORS.lightYellow,textColor:COLORS.yellow},
    [STATUS.PartiallyPaid]:{backgroundColor:COLORS.lightYellow,textColor:COLORS.yellow},
    [STATUS.PendingDisbursement]:{backgroundColor:COLORS.lightYellow,textColor:COLORS.yellow},
    // green
    [STATUS.Active]:{backgroundColor:COLORS.lightGreen,textColor:COLORS.darkgreen},
    [STATUS.Disbursed]:{backgroundColor:COLORS.lightGreen,textColor:COLORS.darkgreen},
    [STATUS.Completed]:{backgroundColor:COLORS.lightGreen,textColor:COLORS.darkgreen},
    // red
    [STATUS.Defaulted]:{backgroundColor:COLORS.lightRed,textColor:COLORS.red},
    [STATUS.ApplicationFailed]:{backgroundColor:COLORS.lightRed,textColor:COLORS.red},
    [STATUS.Inactive]:{backgroundColor:COLORS.lightRed,textColor:COLORS.red},
    [STATUS.Closed]:{backgroundColor:COLORS.lightRed,textColor:COLORS.red},
    [STATUS.Failed]:{backgroundColor:COLORS.lightRed,textColor:COLORS.red},
    [STATUS.Rejected]:{backgroundColor:COLORS.lightRed,textColor:COLORS.red},
    [STATUS.Rejected]:{backgroundColor:COLORS.lightRed,textColor:COLORS.red},
 }
   

