
import { requests } from "../helper/apiHelper";
import { LOANS } from "./endpoints";
import { useQuery } from '@tanstack/react-query'

export async function getLoans(token) {
   
    const response = await requests.get(LOANS.allLoans,token);
    return response;
}


export const useGetAllLoans = (token) => {
    return useQuery({
      queryKey: ["all loans"],
      enabled:!!token,
      queryFn: () => getLoans(token)
    });
  };
