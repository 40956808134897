import React from 'react';
import {
  MRT_GlobalFilterTextField as GlobalFilter,
  useMaterialReactTable,
} from 'material-react-table';
import { MaterialReactTable } from 'material-react-table';
import { Box } from '@mui/material';

const getTableRowStyles = (isOdd) => ({
  ...(isOdd && {
    backgroundColor: '#f0f0f0',
  }),
  '& td, & th': {
    border: 0,
  },
});

const handleSelectAllClick = (tableInstance, setPageRows, canSelectAll) => {
  if (tableInstance.table.getIsAllRowsSelected()) {
    tableInstance.table.setRowSelection({});
  } else {
    const newSelection = {};
    if (canSelectAll) {
      const currentPageRows = tableInstance.table.page.rows.map((row) => row.original);
      setPageRows?.(currentPageRows);
      currentPageRows.forEach((row) => {
        newSelection[row.id] = true;
      });
      tableInstance.table.setRowSelection(newSelection);
    }
  }
};

const CommonTable = ({
  columns,
  data,
  hasSelectAll = false,
  rowSelection,
  rowCheckboxSelectsAll,
  isLoading = false,
  defaultPagination = true,
  setRowSelection,
  setPageRows,
}) => {
  const table = useMaterialReactTable({
    data: data,
    columns,
    positionGlobalFilter: 'top',
    onRowSelectionChange: setRowSelection,
    state: { rowSelection, isLoading: isLoading },
    enableTopToolbar: true,
    enableColumnFilterModes: true,
    enableToolbarInternalActions: true,
    enableFilterMatchHighlighting:true,
    enableColumnActions: false,
    enableRowSelection: hasSelectAll,
    paginationDisplayMode: 'pages',
    enablePagination: defaultPagination,
    autoResetAll: false,
    autoResetPageIndex: false,
    enableFilters:true,
    showGlobalFilter: true,
    initialState: { showGlobalFilter: true },
    renderTopToolbar: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <GlobalFilter table={table} />
        
      </Box>
    ),
    muiSelectAllCheckboxProps: (tableInstance) => ({
      onClick: () => handleSelectAllClick(tableInstance, setPageRows, true),
      sx: {
        color: '#009966',
        borderRadius: '4px',
        height: '16px',
        width: '16px',
        '&.Mui-checked, &.MuiCheckbox-indeterminate': {
          color: '#009966',
        },
      },
    }),
    muiSelectCheckboxProps: (tableInstance) => ({
      onClick: () =>
        rowCheckboxSelectsAll ? handleSelectAllClick(tableInstance, null, false) : null,
      sx: {
        color: '#49454F',
        borderRadius: '4px',
        height: '16px',
        width: '16px',
        '&.Mui-checked': {
          color: '#009966',
        },
      },
    }),
    muiTablePaperProps: {
      elevation: 0,
    },
    muiTableProps: {
      sx: {
        border: '1px #f0f0f0 solid',
        borderRadius: '6px',
      },
    },
    muiTableHeadCellProps: {
      align: 'left',
      sx: {
        fontSize: '14px',
        color: '#212427',
        fontWeight: 600,
      },
    },
    muiTableBodyCellProps: {
      align: 'left',
      color: '#49454F',
      sx: {
        border: '1px gray solid',
      },
    },
    muiTableHeadRowProps: {
      sx: {
        border: 'none',
        boxShadow: 'none',
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx: getTableRowStyles(row.index % 2 === 1),
    }),
    muiPaginationProps: {
      rowsPerPageOptions: [10],
      shape: 'rounded',
      showRowsPerPage: false,
      variant: 'outlined',
      sx: {
        '& .Mui-selected': {
          backgroundColor: 'transparent',
          border: '1px solid #009966',
          color: '#009966',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  });

  return (
    <div className="h-full relative">
    
      <MaterialReactTable table={table} />
    </div>
  );
};

export default CommonTable;
