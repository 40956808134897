import { useRoutes, Navigate } from "react-router";

import Login from "./components/login/login";
import Home from "./components/Home/home";
import Transactions from "./components/Transactions/transactions";
import Loans from "./components/Loans/loans";
import Active from "./components/Loans/Active/active";
import Cancelled from "./components/Loans/Cancelled/cancelled";
import Excise from "./components/Loans/Excise-Duty/exciseDuty";
import Followups from "./components/Loans/No-Follow-Ups/noFollowUps";
import Overdue from "./components/Loans/Overdue/overdue";
import Repaid from "./components/Loans/Repaid/repaid";
import Requests from "./components/Loans/Requested/requested";
import Written from "./components/Loans/WrittenOff/writtenOff";
import BusinessTabs from "./components/Businesses/businessTabs";
import Open from "./components/Support/Open/open";
import Resolved from "./components/Support/Resolved/resolved";
import Notifications from "./components/Notifications/notifications";
import Support from "./components/Support/support";
import Send from "./components/Transactions/Send_money/send-money";
import Payments from "./components/Transactions/Payments/payments";
import AccountMenu from "./components/Account/account";
import SettingsTabs from "./components/Settings/settings";
import Admins from "./components/AccessManagement/admins";
import Messages from "./components/Settings/Messages/messagesSettings";
import KcbWalletTransactions from "./components/KcbWalletTransactions/KcbWalletTransactions";
import AirtimeTabs from "./components/Airtime/airtimeTabs";
import FortuneAccounts from "./components/Fortune-Accounts/fortuneAccounts";
import Disbursed from "./components/Loans/Disbursed/disbursed";
import ReferralCode from "./components/ReferralCode/referralCode";
import ClientTabs from "./components/Users/Tabs";
import LoansRestructured from "./components/Loans";
import LoanTabLayout from "./components/Loans";
import LoanTable from "./components/Loans/LoanTable";

const AppRoutes = () => {
  const isAuthenticated = localStorage.getItem("user") !== null;

  const routes = useRoutes([
    {
      path: "/",
      element: <Navigate to="/login" />,
    },
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "home",
      element: isAuthenticated ? <Home /> : <Navigate to="/login" />,
      children: [
        {
          path: "access-management",
          element: isAuthenticated ? <Admins /> : <Navigate to="/login" />,
        },
        {
          path: "transactions",
          element: isAuthenticated ? (
            <Transactions />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "kcb-wallet-transactions",
          element: isAuthenticated ? (
            <KcbWalletTransactions />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "send-money",
          element: isAuthenticated ? <Send /> : <Navigate to="/login" />,
        },
        {
          path: "payments",
          element: isAuthenticated ? <Payments /> : <Navigate to="/login" />,
        },
        {
          path: "clients",
          element: isAuthenticated ? <ClientTabs /> : <Navigate to="/login" />,
        },
        
        {
          path: "settings",
          element: isAuthenticated ? (
            <SettingsTabs />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "messages-settings",
          element: isAuthenticated ? <Messages /> : <Navigate to="/login" />,
        },
        {
          path: "businesses",
          element: isAuthenticated ? <BusinessTabs /> : <Navigate to="/login" />,
        },
        {
          path: "referral-codes",
          element: isAuthenticated ? (
            <ReferralCode />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "accountmenu",
          element: isAuthenticated ? <AccountMenu /> : <Navigate to="/login" />,
        },
        {
          path: "airtime",
          element: isAuthenticated ? <AirtimeTabs /> : <Navigate to="/login" />,
        },
        {
          path: "fortune_Accounts",
          element: isAuthenticated ? (
            <FortuneAccounts />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "notifications",
          element: isAuthenticated ? (
            <Notifications />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          path: "support",
          element: isAuthenticated ? <Support /> : <Navigate to="/login" />,
          children: [
            {
              path: "support-open",
              element: isAuthenticated ? <Open /> : <Navigate to="/login" />,
            },
            {
              path: "support-resolved",
              element: isAuthenticated ? (
                <Resolved />
              ) : (
                <Navigate to="/login" />
              ),
            },
          ],
        },
       
        // {
        //   path: "loans",
        //   element: isAuthenticated ? <Loans /> : <Navigate to="/login" />,
        //   children: [
        //     // {
        //     //   path: "test-loans",
        //     //   element:  <LoansRestructured /> ,
        //     // },
           
        //     {
        //       path: "active-loans",
        //       element: isAuthenticated ? <Active /> : <Navigate to="/login" />,
        //     },
        //     {
        //       path: "cancelled-loans",
        //       element: isAuthenticated ? (
        //         <Cancelled />
        //       ) : (
        //         <Navigate to="/login" />
        //       ),
        //     },
        //     {
        //       path: "excise-duty-loans",
        //       element: isAuthenticated ? <Excise /> : <Navigate to="/login" />,
        //     },
        //     {
        //       path: "guarantors-loans",
        //       element: isAuthenticated ? (
        //         <Disbursed />
        //       ) : (
        //         <Navigate to="/login" />
        //       ),
        //     },
        //     {
        //       path: "no-follow-ups-loans",
        //       element: isAuthenticated ? (
        //         <Followups />
        //       ) : (
        //         <Navigate to="/login" />
        //       ),
        //     },
        //     {
        //       path: "overdue-loans",
        //       element: isAuthenticated ? <Overdue /> : <Navigate to="/login" />,
        //     },
        //     {
        //       path: "repaid-loans",
        //       element: isAuthenticated ? <Repaid /> : <Navigate to="/login" />,
        //     },
        //     {
        //       path: "requested-loans",
        //       element: isAuthenticated ? (
        //         <Requests />
        //       ) : (
        //         <Navigate to="/login" />
        //       ),
        //     },
        //     {
        //       path: "written-off-loans",
        //       element: isAuthenticated ? <Written /> : <Navigate to="/login" />,
        //     },
        //   ],
        // },

        // prove concept of re-usable tabs
        {
          path: "loans",
          element: isAuthenticated ? <LoanTabLayout /> : <Navigate to="/login" />,
          children: [
            {
              path: "loans",
              element:  <LoanTable /> ,
            },
            {
              path: "active-loans",
              element: isAuthenticated ? <Active /> : <Navigate to="/login" />,
            },
            {
              path: "cancelled-loans",
              element: isAuthenticated ? (
                <Cancelled />
              ) : (
                <Navigate to="/login" />
              ),
            },
            {
              path: "excise-duty-loans",
              element: isAuthenticated ? <Excise /> : <Navigate to="/login" />,
            },
            {
              path: "guarantors-loans",
              element: isAuthenticated ? (
                <Disbursed />
              ) : (
                <Navigate to="/login" />
              ),
            },
            {
              path: "no-follow-ups-loans",
              element: isAuthenticated ? (
                <Followups />
              ) : (
                <Navigate to="/login" />
              ),
            },
            {
              path: "overdue-loans",
              element: isAuthenticated ? <Overdue /> : <Navigate to="/login" />,
            },
            {
              path: "repaid-loans",
              element: isAuthenticated ? <Repaid /> : <Navigate to="/login" />,
            },
            {
              path: "requested-loans",
              element: isAuthenticated ? (
                <Requests />
              ) : (
                <Navigate to="/login" />
              ),
            },
            {
              path: "written-off-loans",
              element: isAuthenticated ? <Written /> : <Navigate to="/login" />,
            },
           
            
            
          ],
        },
      ],
    },

   
  ]);

  return routes;
};

export default AppRoutes;
// import { useRoutes, Navigate } from "react-router";
// import { useEffect, useState } from "react";
// import { collection, getDocs, query, where } from "firebase/firestore";
// import { db } from "./firebase/firebase";

// import Login from "./components/login/login";
// import Home from "./components/Home/home";
// import Transactions from "./components/Transactions/transactions";
// import Loans from "./components/Loans/loans";
// import Active from "./components/Loans/Active/active";
// import Cancelled from "./components/Loans/Cancelled/cancelled";
// import Excise from "./components/Loans/Excise-Duty/exciseDuty";
// import Followups from "./components/Loans/No-Follow-Ups/noFollowUps";
// import Overdue from "./components/Loans/Overdue/overdue";
// import Repaid from "./components/Loans/Repaid/repaid";
// import Requests from "./components/Loans/Requested/requested";
// import Written from "./components/Loans/WrittenOff/writtenOff";
// import Business from "./components/Businesses/business";
// import Open from "./components/Support/Open/open";
// import Resolved from "./components/Support/Resolved/resolved";
// import Notifications from "./components/Notifications/notifications";
// import Support from "./components/Support/support";
// import Send from "./components/Transactions/Send_money/send-money";
// import Payments from "./components/Transactions/Payments/payments";
// import AccountMenu from "./components/Account/account";
// import SettingsTabs from "./components/Settings/settings";
// import Admins from "./components/AccessManagement/admins";
// import Messages from "./components/Settings/Messages/messagesSettings";
// import Clients from "./components/Users/users";
// import AirtimeTabs from "./components/Airtime/airtimeTabs";
// import FortuneAccounts from "./components/Fortune-Accounts/fortuneAccounts";
// import Disbursed from "./components/Loans/Disbursed/disbursed";
// import ReferralCode from "./components/ReferralCode/referralCode";
// import NoRoleFound from "./components/shared/NotFound/noRoleFound";

// const AppRoutes = () => {
//   const [user, setUser] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [userDataFetched, setUserDataFetched] = useState(false);
//   const [roleExists, setRoleExists] = useState(false);
//   const [routeReady, setRouteReady] = useState(false);

//   const isAuthenticated = localStorage.getItem("user") !== null;

//   useEffect(() => {
//     const fetchUser = async () => {
//       const storedUser = localStorage.getItem("user");
//       let fetchedUser = null;

//       if (storedUser) {
//         const userEmail = JSON.parse(storedUser).user?.email;
//         console.log("Email exists >>> ", userEmail);

//         if (userEmail) {
//           const userQuery = query(
//             collection(db, "admins"),
//             where("email", "==", userEmail)
//           );
//           console.log("Email exists >>> ", userEmail);

//           const userSnapshot = await getDocs(userQuery);

//           userSnapshot.forEach((doc) => {
//             fetchedUser = { ...doc.data(), id: doc.id };
//           });
//         }
//       }

//       localStorage.setItem("accessRole", fetchedUser?.accessRole);

//       if (fetchedUser && fetchedUser) {
//         setUser(fetchedUser);
//       }
//       console.log("Fetched user >>> ", fetchedUser);

//       setLoading(false);
//       setUserDataFetched(true);
//     };

//     fetchUser();
//   }, [isAuthenticated]);

//   useEffect(() => {
//     const roleExists =
//       userDataFetched &&
//       user !== null &&
//       Object.keys(user).includes("accessRole");
//       console.log("User keys >>> ", Object.keys(user));

//     setRoleExists(roleExists);
//     setRouteReady(roleExists);
//   }, [userDataFetched, user]);
  
//   console.log("role exists >>> ", roleExists);
//   console.log("authenticated >>> ", isAuthenticated);
//   console.log("user >>> ", user);
//   console.log("routes ready >>> ", routeReady);

//   const routes = useRoutes([
//     {
//       path: "/",
//       element: isAuthenticated ? (
//         <Navigate to="/home" />
//       ) : (
//         <Navigate to="/login" />
//       ),
//     },
//     {
//       path: "login",
//       element: <Login />,
//     },
//     {
//       path: "no-role-found",
//       element: isAuthenticated ? <NoRoleFound /> : <Navigate to="/login" />,
//     },
//     {
//       path: "home",
//       element: routeReady ? (
//         isAuthenticated && roleExists ? (
//           <Home />
//         ) : !isAuthenticated ? (
//           <Navigate to="/login" />
//         ) : (
//           <Navigate to="/no-role-found" />
//         )
//       ) : null,
//       children: [
//         {
//           path: "access-management",
//           element: isAuthenticated ? <Admins /> : <Navigate to="/login" />,
//         },
//         {
//           path: "transactions",
//           element: isAuthenticated ? (
//             <Transactions />
//           ) : (
//             <Navigate to="/login" />
//           ),
//         },
//         {
//           path: "send-money",
//           element: isAuthenticated ? <Send /> : <Navigate to="/login" />,
//         },
//         {
//           path: "payments",
//           element: isAuthenticated ? <Payments /> : <Navigate to="/login" />,
//         },
//         {
//           path: "clients",
//           element: isAuthenticated ? <Clients /> : <Navigate to="/login" />,
//         },
//         {
//           path: "settings",
//           element: isAuthenticated ? (
//             <SettingsTabs />
//           ) : (
//             <Navigate to="/login" />
//           ),
//         },
//         {
//           path: "messages-settings",
//           element: isAuthenticated ? <Messages /> : <Navigate to="/login" />,
//         },
//         {
//           path: "businesses",
//           element: isAuthenticated ? <Business /> : <Navigate to="/login" />,
//         },
//         {
//           path: "referral-codes",
//           element: isAuthenticated ? (
//             <ReferralCode />
//           ) : (
//             <Navigate to="/login" />
//           ),
//         },
//         {
//           path: "accountmenu",
//           element: isAuthenticated ? <AccountMenu /> : <Navigate to="/login" />,
//         },
//         {
//           path: "airtime",
//           element: isAuthenticated ? <AirtimeTabs /> : <Navigate to="/login" />,
//         },
//         {
//           path: "fortune_Accounts",
//           element: isAuthenticated ? (
//             <FortuneAccounts />
//           ) : (
//             <Navigate to="/login" />
//           ),
//         },
//         {
//           path: "notifications",
//           element: isAuthenticated ? (
//             <Notifications />
//           ) : (
//             <Navigate to="/login" />
//           ),
//         },
//         {
//           path: "support",
//           element: isAuthenticated ? <Support /> : <Navigate to="/login" />,
//           children: [
//             {
//               path: "support-open",
//               element: isAuthenticated ? <Open /> : <Navigate to="/login" />,
//             },
//             {
//               path: "support-resolved",
//               element: isAuthenticated ? (
//                 <Resolved />
//               ) : (
//                 <Navigate to="/login" />
//               ),
//             },
//           ],
//         },
//         {
//           path: "loans",
//           element: isAuthenticated ? <Loans /> : <Navigate to="/login" />,
//           children: [
//             {
//               path: "active-loans",
//               element: isAuthenticated ? <Active /> : <Navigate to="/login" />,
//             },
//             {
//               path: "cancelled-loans",
//               element: isAuthenticated ? (
//                 <Cancelled />
//               ) : (
//                 <Navigate to="/login" />
//               ),
//             },
//             {
//               path: "excise-duty-loans",
//               element: isAuthenticated ? <Excise /> : <Navigate to="/login" />,
//             },
//             {
//               path: "guarantors-loans",
//               element: isAuthenticated ? (
//                 <Disbursed />
//               ) : (
//                 <Navigate to="/login" />
//               ),
//             },
//             {
//               path: "no-follow-ups-loans",
//               element: isAuthenticated ? (
//                 <Followups />
//               ) : (
//                 <Navigate to="/login" />
//               ),
//             },
//             {
//               path: "overdue-loans",
//               element: isAuthenticated ? <Overdue /> : <Navigate to="/login" />,
//             },
//             {
//               path: "repaid-loans",
//               element: isAuthenticated ? <Repaid /> : <Navigate to="/login" />,
//             },
//             {
//               path: "requested-loans",
//               element: isAuthenticated ? (
//                 <Requests />
//               ) : (
//                 <Navigate to="/login" />
//               ),
//             },
//             {
//               path: "written-off-loans",
//               element: isAuthenticated ? <Written /> : <Navigate to="/login" />,
//             },
//           ],
//         },
//       ],
//     },
//   ]);

//   return loading ? null : routes;
// };

// export default AppRoutes;
